<template>
    <div>
        <FlightsNavbar v-if="isFlights" :experience="experience"/>
        <FlowBreadcrumbs/>
        <router-view></router-view>
    </div>
</template>

<script>
import FlowBreadcrumbs from './FlowBreadcrumbs.vue';
import FlightsNavbar from './Steps/Flights/FlightsNavbar.vue';
import { mapActions, mapGetters } from 'vuex';
//import HotelSelection from './Steps/Hotels/MobileHotelSelection.vue';
export default {
  name: 'MobilePackageSteps',
  data() {
    return {
    };
  },
  components: {
    FlightsNavbar,
    FlowBreadcrumbs,
    //HotelSelection,
  },
  computed: {
    ...mapGetters([
      'experience',
    ]),
    isFlights() {
      return this.$route.name === 'MobileFlights' || 'MobileReturnFlights';
    },
  },
  async mounted() {
    try {
      await this.loadExperience(this.$route.params.experienceId);
    } catch (e) {
      console.log(e);
      this.$router.push({ name: 'LandingPage' });
    }
  },
  methods: {
    ...mapActions([
      'loadExperience',
    ]),
  }
};
</script>

<style lang="scss" scoped>

</style>
