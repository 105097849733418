<template>
    <div class="nav-container">
        <div class="tw-mt-4 tw-mb-4">
        <div
        class="chevron chevron-left tw-ml-4"
        @click="goBack()"
      >
        <v-icon class="package-chevron">mdi-chevron-left </v-icon>
      </div>
      <div class="tw-flex tw-justify-center tw--mt-4 city-name">
      {{fromCity}} to {{this.city.name}}
        </div>
        </div>
    </div>
</template>

<script> 
import axios from 'axios';
export default {
  name: 'FlightsNav',
  data() {
    return {
      cities: null,
      fromCity: this.$route.query.city,
    };
  },
  props: {
    experience: Object
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    this.cities = await axios.get(
      'https://travelo-91156-default-rtdb.europe-west1.firebasedatabase.app/destination_cities.json'
    );
  },
  computed: {
    city: {
      get() {
        try {
          return this.cities.data[this.experience.city_id];
        } catch (err) {
          return null;
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
.nav-container {
    box-shadow: 0px 4px 6px rgba(224, 224, 224, 0.04), inset 0px -1px 0px #f0f0f0;
}
.chevron > i {
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
  border-radius: 1.5rem;
}

.v-icon.package-chevron {
    color: black !important;
    padding-top: 0.5rem;
}

.city-name {
    font-family: 'SFProDisplaySemibold';
    color: $title;
    line-height: 1.25rem;
    letter-spacing: 0.03rem;
    font-size: 1rem;
    padding-bottom: 1rem;
}

</style>

